$background: #000;
$pink: #de01c7;
$gold: $pink;
$outlineFocus: #caa243;
$gray: #92a8bd;
$grey: #92a8bd;

$error: #ff7070;
$borderRadius: 10px;

$salmon: #f79b77;
$navy: #102a49;

$lovies-2022-black: #030303;
$lovies-2022-blue: #0c23fd;
$lovies-2022-yellow: #ffce00;
$lovies-2022-pink: #ff5aba;
$lovies-2022-red: #f00;
$lovies-2022-white: #d9d1e3;

$foreground-color: $lovies-2022-black;
$background-color: $lovies-2022-pink;
$accent-color: $lovies-2022-white;
$tertiary-color: $lovies-2022-yellow;



$mobile: 769px;

$xl: 1300px;

$headerHeight: 50px;

$minHeight: calc(100vh - #{$headerHeight} - #{$headerHeight});
$borderH: 7vh;
$borderW: 7vw;

$borderHMobile: 2vh;
$borderWMobile: 5vw;

$bodyFont: "Nimbus Sans", monospace;
//$fancyFont: 'clarendon_btbold', sans-serif;
//$boldFont: 'clarendon_btbold', sans-serif;

$fancyFont: "Nimbus Sans", monospace;
$boldFont: "Nimbus Sans", monospace;

$newFontBold: "Nimbus Sans", monospace;
$newFont: "Nimbus Sans", monospace;

$font-color: $foreground-color;
$yellow: #f7ee5e;
