@import "../vars.scss";

.root {
  text-align: center;
  line-height: 1.5;
  height: 100vh;
}

.vert {
  position: relative;
  z-index: 2;
}

.biglogo {
  position: absolute;
  z-index: 1;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  > div > svg {
    margin-top: 20px;
    width: 1600px !important;
  }

  @media (max-width: 1100px) {
    > div > svg {
      margin-top: 30px;
      width: 1400px !important;
    }
  }

  @media (max-width: 600px) {
    > div > svg {
      margin-top: 40px;
      width: 900px !important;
    }
  }
}

.topText {
  font-size: 20px;
  position: relative;
  z-index: 2;

  padding-top: 307px;

  @media (max-width: 1100px) {
    padding-top: 260px;
  }

  @media (max-width: 600px) {
    padding-top: 179px;
    font-size: 16px;
  }
}

.host {
  margin-top: 254px;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.2;
  position: relative;
  z-index: 2;

  @media (max-width: 1100px) {
    margin-top: 250px;
  }

  @media (max-width: 600px) {
    margin-top: 200px;
  }
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 40px;

  > * {
    width: 300px;
    margin-bottom: 0;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    gap: 0;
    margin: 0 20px;

    > * {
      width: initial;
    }

    > *:first-child {
      margin-bottom: 20px;
    }
  }
}

.tickerTitle {
  margin-top: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.ticker {
  overflow: hidden;
  background-color: #d6bfbb;
  white-space: nowrap;
  text-transform: uppercase;
  position: relative;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 40px;
  z-index: 2;
}

.tickerNames {
  position: absolute;
  display: block;
  color: $background-color;
  line-height: 50px;
  font-size: 24px;
  padding-top: 4px; // fix for shitty font
}

$speed: 50s;

.ticker1 {
  animation: marquee1 $speed linear infinite;
}

.ticker2 {
  animation: marquee2 $speed linear infinite;
  // color: red;
  transform: translateX(100%);
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee1 {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  50.0000000001% {
    transform: translateX(100%);
  }
}
