@import "../vars";

.backButton {
  cursor: pointer;
  background-color: lighten($accent-color, 20%);
  color: #000;
  text-transform: uppercase;
  display: inline-block;
  padding: 20px 20px 17px 20px;
  margin-top: 20px;
}
