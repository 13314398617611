@import "../vars.scss";

.root {
  cursor: pointer;
  text-decoration: none;
  background-color: $accent-color;
  color: $lovies-2022-black;
  text-transform: uppercase;
  display: inline-block;
  padding: 20px 20px 17px 20px;

  &:hover {
    background-color: $lovies-2022-black;
    color: $foreground-color;
  }
}

.nopad {
  padding: 0;
}

.italic {
  font-style: italic;
  font-size: 1.5em;
}

.wide {
  padding-left: 40px;
  padding-right: 40px;
}

.outline {
  background-color: transparent;
  color: $accent-color;
  border: 2px solid $accent-color;
  border-radius: 999px;
  font-size: 20px;

  &:hover {
    background-color: $accent-color;
    color: $background-color;
  }
}
