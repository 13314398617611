@import "../vars";

.root {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;

  svg {
    width: 150px;
    height: 120px;
    display: block;
    padding: 20px;
    margin: 0 auto;

    animation-name: pulsePolygon;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    // transform: translateX(-100px);
  }
}

.inner {
  background: $accent-color;
  padding: 10px;
  text-transform: uppercase;
  border: 5px solid $accent-color;
  text-align: center;
}

.text {
  color: $background-color;
}

@keyframes pulsePolygon {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
