@import "vars";

.root {
  //background-color: lighten($accent-color, 40%);
  background-color: $lovies-2022-blue;
  text-align: center;
  //color: black;
  padding: 30px;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  margin: 0 20px;
}

.heading {
  text-transform: uppercase;
  color: white;
}
