.root {
  text-align: center;
}

.bottomText {
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 20px;

  @media (max-width: 790px) {
    font-size: 20px;
  }

  @media (max-width: 590px) {
    font-size: 18px;
  }
}

.animationHolder {
  flex-grow: 1;

  & > div {
    height: 100%;
  }

  @media (max-width: 900px) {
    flex-grow: initial;
  }
}

.content {
  height: 100vh;
  display: flex;
  flex-direction: column;

  & > *:first-child {
    min-width: 0;
    min-height: 0;
    overflow: hidden;
  }

  @media (max-width: 900px) {
    justify-content: center;
  }
}
